<template>
  <div class="app">
    <div class="hd" style="display: flex; justify-content: space-between;padding:0 30px">
      <span  @click="$router.back()">返回</span>
      <div class="homeSearch">
        <md-icon name="search" class="ic"></md-icon>
        <input type="text" v-model="keyword">
        <div class="sbt" @click="dosearch">搜索</div>
      </div>

    </div>

      <div class="main">
        <md-scroll-view
            :scrolling-x="false"
            auto-reflow
            @end-reached="handleToEnd"
            @refreshing="handleToTop"
            ref="sc"
        >
          <md-scroll-view-refresh
              slot="refresh"
              slot-scope="{ scrollTop, isRefreshActive, isRefreshing }"
              :scroll-top="scrollTop"
              :is-refreshing="isRefreshing"
              :is-refresh-active="isRefreshActive"
          ></md-scroll-view-refresh>


          <div class="tuwen" >

              <div class="twitem" v-for="item of list" :key="item.aid" @click="$router.push({name:'detail',query:{id:item.aid}})">

                <div class="info">
                  <div class="title">{{ item.title }}</div>
                  <div class="desc" >{{ item.contentSummary.slice(0,20) | htmlfilter }}</div>
                  <div class="detail">
                    <div class="author">{{ item.author }}</div>
                    <div class="time">{{item.addTime|format('YYYY-MM-DD')}}</div>
                  </div>
                </div>
              </div>



          </div>
          <md-scroll-view-more
              slot="more"
              :is-finished="isFinished"
          >
          </md-scroll-view-more>
        </md-scroll-view>
      </div>
  </div>



</template>

<script>
export default {
name: "List",
  data(){
    return {
      items: [
        {name: 1, label: '全部'},
        {name: 2, label: '浏览量'},
        {name: 3, label: '最新发布'},
      ],
      subCates:[

      ],
      curCate:null,
      orderBy: 1,
      typeBy:'',
      list:[],
      page:1,
      count:0,
      isFinished:false,
      keyword:'',
      istuwen2:false,
      fenlei:[]
    }
  },

  watch:{
    curCate(nv){
      this.isFinished = false;
      this.list = []
      this.getData();
      this.getCateInfo()
    },
    typeBy(nv){
      this.isFinished = false;
      this.list = []
      this.getData();
      this.getCateInfo()
    }
  },
  activated() {
    this.keyword = this.$route.query.keyword;
    this.list=[]
    this.getData()
  },
  created(){
      this.keyword = this.$route.query.keyword;
      if(this.$route.query.big == 1) this.istuwen2 = true

    console.log('get here')
  },
  mounted(){
    this.keyword = this.$route.query.keyword;
    this.list=[]
      this.getData()

  },
  methods:{

     async dosearch(){

        this.isFinished = false;
        this.page = 1;
        this.list = []
        this.getData()
     },
     async getSubCates(){
       let res = await this.$api.post('/mapi/getSubCates',{
         cid:this.$route.query.cid
       })
       const { success,data } = res.data
       if(success){
         this.subCates = data.map(r=>({
           name:r.cid,
           label:r.name
         }))
         this.curCate = this.$route.query.cid

       }

     },
     async handleTabChange(){
       this.list = []
       this.page = 1;
       this.getData();
       this.isFinished = false
     },
     async getData(){
          if(this.isFinished)  return false


          let res = await this.$api.post('/mapi/search',{
                page:this.page,
                limit:10,
                keyword:this.keyword,
          })
          const  { count,rows,success}  = res.data;
          if(success){
            this.count = count
            this.list = this.list.concat(rows)
            this.$refs.sc.finishLoadMore()
            if(this.list.length >= this.count) this.isFinished = true
          }

     },
     async handleToEnd(){
        if(!this.isFinished)  this.page++
       this.getData()

     },
    async handleToTop(){
       this.list = []
       this.page = 1;
       this.isFinished = false
      await this.getData()
      this.$refs.sc.finishRefresh()
    }
  }
}
</script>

<style lang="scss" scoped>
.hd {
  span:first-child{
    margin-top: 10px;
  }
}
.main{
  height: 0;
  flex-grow: 1;
}
::v-deep {
  .md-tab-bar-item{
    min-height: 60px;
  }
  .sub{
    border-bottom: 1px solid #d4d4d4;
  }
  .sub .md-tab-bar-item{
    min-height: 100px;
  }
}
.twitem{
  border-bottom: 1px solid #dbdbdb;
  padding: 20px;
  .title{
    font-size: 26px;
    font-weight: 700;
  }
  .desc{
    font-size: 20px;
    color:#5a5a5a;
  }
}
</style>
